<template>
  <div class="casedetail-container">
    <div class="casedetail-main" v-show="routejump == false">
      <div class="casedetail-header" v-if="status === 2">
        <div class="casedetail-header-left">
          答复期倒计时：<span class="casedetail-header-date">
            {{ day }} 天 {{ hour }} 时 {{ min }} 分 {{ second }} 秒</span
          >
        </div>
        <div class="casedetail-header-right">
          <el-button
            :class="[schemeBtn == true ? 'scheme-disabled' : '']"
            @click="goScheme"
            :disabled="schemeBtn"
            >调解方案</el-button
          >
        </div>
      </div>
      <div class="replied-status" v-if="status === 3">已答复</div>
      <div class="casedetail-body">
        <div class="casedetail-table">
          <div class="casedetail-table-item">
            <div class="table-item">
              <div class="casedetail-table-item-lable">受理单位</div>
              <div class="casedetail-table-item-content">景德镇仲裁委</div>
            </div>
            <div class="table-item">
              <div class="casedetail-table-item-lable">案号</div>
              <div class="casedetail-table-item-content">
                {{ caseInfo.caseNo }}
              </div>
            </div>
          </div>
          <div class="casedetail-table-item">
            <div class="table-item">
              <div class="casedetail-table-item-lable">申请调解人</div>
              <div class="casedetail-table-item-content">
                {{ mediatorname }}
              </div>
            </div>
            <div class="table-item">
              <div class="casedetail-table-item-lable">被申请调解人</div>
              <div class="casedetail-table-item-content">
                {{ respondentsname }}
              </div>
            </div>
          </div>
        </div>
        <div class="casedetail-menu">
          <el-tabs v-model="activeMenu" type="card" @tab-click="handleClick">
            <el-tab-pane label="申请调解人材料">
              <div
                class="applicationData-content"
                v-if="applicationData.length > 0"
              >
                <div
                  class="applicationData-item"
                  v-for="(item, index) in applicationData"
                  :key="index"
                >
                  <!-- <a target="noDwonLoad" :href="`/web/preview/previewfile?download=1&filePath=${item.filePath}`">{{ item.fileName }}</a> -->
                  <!-- <iframe style="display:none" name='noDwonLoad'></iframe> -->
                  <div>
                    <a
                      :href="
                        '/web/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      >{{ item.fileName }}</a
                    >
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'pdf'">
                    <pdf
                      v-for="i in numPages"
                      :key="i"
                      :page="i"
                      ref="pdf"
                      :src="pdfUrl"
                    ></pdf>
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'png'">
                    <iframe
                      :src="pngUrl"
                      frameborder="0"
                      width="100%"
                      height="600"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                <div class="empty-img">
                  <img src="../assets/images/index/empty.png" />
                </div>
                <div class="empty-text">暂无相关数据</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="受理材料">
              <div
                class="acceptanceData-content"
                v-if="acceptanceData.length > 0"
              >
                <div
                  class="acceptanceData-item"
                  v-for="(item, index) in acceptanceData"
                  :key="index"
                >
                  <!-- <a
                    target="noDwonLoad"
                    :href="`/web/preview/previewfile?download=1&filePath=${items.filePath}`"
                    >{{ items.fileName }}</a
                  >
                  <iframe style="display: none" name="noDwonLoad"></iframe> -->
                  <div>
                    <a
                      :href="
                        '/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      >{{ item.fileName }}</a
                    >
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'pdf'">
                    <pdf
                      v-for="i in numPagesTwo"
                      :key="i"
                      :page="i"
                      ref="pdf"
                      :src="pdfUrlTwo"
                    ></pdf>
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'png'">
                    <iframe
                      :src="pngUrlTwo"
                      frameborder="0"
                      width="100%"
                      height="600"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                <div class="empty-img">
                  <img src="../assets/images/index/empty.png" />
                </div>
                <div class="empty-text">暂无相关数据</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="调解意见书">
              <div
                class="respondentData-content"
                v-if="respondentData.length > 0"
              >
                <div
                  class="respondentData-item"
                  v-for="(item, index) in respondentData"
                  :key="index"
                >
                  <!-- <a
                    target="noDwonLoad"
                    :href="`/web/preview/previewfile?download=1&filePath=${itemss.filePath}`"
                    >{{ itemss.fileName }}</a
                  >
                  <iframe style="display: none" name="noDwonLoad"></iframe> -->
                  <div>
                    <a
                      :href="
                        '/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      >{{ item.fileName }}</a
                    >
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'pdf'">
                    <pdf
                      v-for="i in numPagesThree"
                      :key="i"
                      :page="i"
                      ref="pdf"
                      :src="pdfUrlThree"
                    ></pdf>
                  </div>
                  <div v-if="item.filePath.slice(-3) == 'png'">
                    <iframe
                      :src="pngUrlThree"
                      frameborder="0"
                      width="100%"
                      height="600"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                <div class="empty-img">
                  <img src="../assets/images/index/empty.png" />
                </div>
                <div class="empty-text">暂无相关数据</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="route-main" v-show="routejump == true">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  name: "CaseDetail",
  components: { pdf },
  data() {
    return {
      activeMenu: 0,
      routejump: false, // 跳转路径
      id: "",
      respondentsname: "",
      mediatorname: "", // 申请调解人
      schemeBtn: false, // 控制答复时间超时禁用按钮

      applicationData: [], // 申请人调解材料
      acceptanceData: [], // 受理材料
      respondentData: [], // 被申请人调解材料

      pdfUrl: "", // 预览pdf
      pdfUrlTwo: "",
      pdfUrlThree: "",

      pngUrl: "", // 预览图片
      pngUrlTwo: "",
      pngUrlThree: "",

      numPages: null,
      numPagesTwo: null,
      numPagesThree: null,

      caseInfo: {},
      status: "", // 答复状态
      curStartTime: "",
      day: "0",
      hour: "00",
      min: "00",
      second: "00",
    };
  },
  mounted() {
    // console.log(this.$route.path);
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    } else {
      let $mediateCaseId = sessionStorage.getItem("mediateCaseId");
      this.id = $mediateCaseId;
    }
    this.getCaseInfo();
    // this.getDefenceDetail();
    if (this.$route.path != "/casedetail") {
      this.routejump = true;
    }
  },
  watch: {
    $route: function (newUrl, oldUrl) {
      // console.log(newUrl, oldUrl);
      if (newUrl.path == "/casedetail") {
        this.routejump = false;
      }
      if (newUrl.path == "/casedetail/scheme") {
        this.routejump = true;
      }
    },
  },
  methods: {
    // 答复倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let nowTime = date.getTime();
      let endDate = new Date(this.curStartTime); // 需要倒计时的时间
      let endTime = endDate.getTime();
      let differenceTime = endTime - nowTime; // 需要倒计时时间 - 当前时间
      if (differenceTime > 0) {
        this.day = Math.floor(differenceTime / 1000 / 60 / 60 / 24);
        let h = Math.floor((differenceTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        let m = Math.floor((differenceTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        let s = Math.floor((differenceTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.day = 0;
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      if (
        Number(this.day) === 0 &&
        Number(this.hour) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        this.schemeBtn = true;
      } else {
        setTimeout(this.countTime, 1000);
      }
    },
    // 答辩详情
    getDefenceDetail() {
      this.$axios
        .get(`/web/web/caseReplyLog/info?caseId=${this.id}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.respondentData = res.data.data;
            // res.data.data.map(item => {
            // console.log(item);
            // this.respondentData = item.filePathList
            // })
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    //获取调解案件详情
    getCaseInfo() {
      let caseId = this.id;
      // console.log(caseId);
      this.$axios
        .get(`/web/web/case/info?id=${caseId}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.caseInfo = res.data.data;
            if (
              this.caseInfo.mdProposerFileList &&
              (this.caseInfo.mdProposerFileList != "" ||
                this.caseInfo.mdProposerFileList != null)
            ) {
              this.applicationData = this.caseInfo.mdProposerFileList;
            }
            if (
              this.caseInfo.acceptFileList &&
              (this.caseInfo.acceptFileList != "" ||
                this.caseInfo.acceptFileList != null)
            ) {
              this.acceptanceData = this.caseInfo.acceptFileList;
            }
            if (
              this.caseInfo.mdRespondentFileList &&
              (this.caseInfo.mdRespondentFileList != "" ||
                this.caseInfo.mdRespondentFileList != null)
            ) {
              this.respondentData = this.caseInfo.mdRespondentFileList;
            }

            this.applicationData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrl = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlTask = pdf.createLoadingTask(this.pdfUrl);
                pdfUrlTask.promise
                  .then((pdf) => {
                    this.numPages = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrl = `/web/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.acceptanceData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrlTwo = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlTwoTask = pdf.createLoadingTask(this.pdfUrlTwo);
                pdfUrlTwoTask.promise
                  .then((pdf) => {
                    this.numPagesTwo = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrlTwo = `/web/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.respondentData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrlThree = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlThreeTask = pdf.createLoadingTask(this.pdfUrlThree);
                pdfUrlThreeTask.promise
                  .then((pdf) => {
                    this.numPagesThree = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrlThree = `/web/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.status = this.caseInfo.status;
            this.curStartTime = this.caseInfo.replyUpTime;
            this.countTime();
            this.mediatorname = this.caseInfo.mdProposer.name;
            let mdRespondents = this.caseInfo.mdRespondents;
            for (var i = 0; i < mdRespondents.length; i++) {
              if (mdRespondents.length > 1) {
                this.respondentsname =
                  mdRespondents[i].name + "," + mdRespondents[i + 1].name;
              } else {
                this.respondentsname = mdRespondents[i].name;
              }
            }
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          return;
          // this.$message({
          //   message: "请求异常，服务器出错",
          // });
        });
    },
    goScheme() {
      this.routejump = true;
      this.$router.push({ name: "Scheme", query: { id: this.id } });
    },
    handleClick(e) {
      //   console.log(e);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/casedetail.scss";
</style>